import React from "react"
import { motion } from "framer-motion"

const HoverMoveText = () => {
    const textVariants = {
        initial: { y: 0 },
        hover: { y: -10 }, // posun nahoru o 10 pixelů při najetí myši
    }

    return (
        <motion.div
            initial="initial"
            whileHover="hover"
            style={{
                fontSize: "2rem",
                textAlign: "center",
                margin: "2rem",
                padding: "1rem",
                background: "#333", // Tmavé pozadí, aby byl bílý text viditelný
                color: "#ffffff", // Bílá barva textu
                borderRadius: "8px",
                cursor: "pointer",
            }}
            variants={textVariants}
            transition={{ duration: 0.3 }} // Plynulý přechod
        >
            Design Studio Next Gen
        </motion.div>
    )
}

export default HoverMoveText
